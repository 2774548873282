export const environment = {
  production: false,
  mode: 'dev',
  placesApiKey: 'AIzaSyAM5xfoh5V8brYtzEwiIFGgLhId5Mu3CAM',

  shortName: 'sk',
  domainName: 'der-sofortkredit.de',
  domain: '.c2-skdev.de',

  loggingUrl: 'https://backend.c2-skdev.de/v1/log/write',
  backendUrl: 'https://backend.c2-skdev.de/backend/request',
  mainUrl: 'https://portal.c2-skdev.de',
  marketingUrl: 'https://sofortkredit.vwfs.de',
  triggerSimulationUrl: 'https://backend.c2-skdev.de/backend/urlreferral/trigger_callback',
  merchantTermsUrl: 'https://partner.der-sofortkredit.de/assets/FAB_TAC.pdf',

  enableMarketingEnrollment: true,
  productType: 'SOFORTKREDIT',
  brandName: 'Sofortkredit',

  counterProductUrl: 'https://portal.dev-financeabike.de/#/portal/login'
};
